import React, {useEffect} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {AppBskyFeedDefs, AppBskyFeedPost} from '@atproto/api'
import {Trans} from '@lingui/macro'

import {useGetTimeAgo} from '#/lib/hooks/useTimeAgo'
import {Shadow} from '#/state/cache/types'
import {useAgent} from '#/state/session'
import {useTickEveryMinute} from '#/state/shell'
import {UserIcon} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {useTheme} from '#/alf'
import {ICircleItemConfig} from '../util/CircleUtil'
import {getRelationship} from '../util/sdlStore/Relationship'

export function CirclePostSource({
  timestamp,
  post,
  record,
}: {
  record: AppBskyFeedPost.Record
  post: Shadow<AppBskyFeedDefs.PostView>
  timestamp: string
  circleItemConfig?: ICircleItemConfig | undefined
}) {
  const t = useTheme()
  const ago = useGetTimeAgo()
  const tick = useTickEveryMinute()
  const time = ago(timestamp, tick)

  const [relations, setRelations] = React.useState<string>('')
  const agent = useAgent()

  useEffect(() => {
    getRelationship(post.author.did, agent).then(res => {
      setRelations(res)
    })
  }, [agent, post.author.did])

  return (
    <View style={styles.page}>
      <Text
        style={[t.atoms.text, {overflow: 'hidden', maxWidth: 150}]}
        numberOfLines={1}>
        {record.hideAuthor === 'true' ? (
          <Trans>Anonymous</Trans>
        ) : post.author.displayName ? (
          post.author.displayName
        ) : (
          formatHandler(post.author.handle)
        )}
      </Text>
      <Text style={t.atoms.text}>{`  · ${time}`}</Text>
      <View style={{flex: 1}} />
      <UserIcon />
      <Text style={[t.atoms.text, {marginLeft: 3, fontWeight: 'bold'}]}>
        {relations}
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    paddingLeft: 10,
    width: '100%',
    paddingBottom: 7,
    flexDirection: 'row',
  },
})
