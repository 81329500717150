import React, {useEffect, useMemo} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg'
import {Image} from 'expo-image'

import {isMobileWeb} from '#/platform/detection'
import {useTheme} from '#/alf'
import {getBackgroundColor, useThemeName} from '#/alf/util/useColorModeTheme'
import {Button} from '#/components/Button'
import {IconCircle} from '#/components/IconCircle'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {SdlSVG} from './ProfileIcons'

interface BottomIconProps {
  size?: number
  style?: StyleProp<ViewStyle>
  active?: boolean
}

const Install_Guide = 'Install_Guide'

export const HomeIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {color: '#292D32', activeColor: 'black', colordown: 'white'}
      : {color: 'white', activeColor: 'white', colordown: 'black'}
  }, [theme])

  return (
    <SdlSVG
      defaultSize={25}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      {active ? (
        <>
          <Path
            d="M3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016L3.63 7.04016Z"
            fill={colors.activeColor}
            stroke={colors.activeColor}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M12 17.9902V14.9902"
            stroke={colors.colordown}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <Path
            d="M3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016L3.63 7.04016Z"
            stroke={colors.color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M12 17.9902V14.9902"
            stroke={colors.color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </SdlSVG>
  )
}

export const ProfileIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {activeColor: '#292D32', colordown: 'white'}
      : {activeColor: 'white', colordown: 'black'}
  }, [theme])

  return (
    <SdlSVG
      defaultSize={25}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      {active ? (
        <>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8014 2H12.5V0H21.8014C23.5655 0 24.5435 2.04354 23.4368 3.41739L23.4068 3.45456L21.2155 5.69865L19.7845 4.30135L21.8939 2.1412C21.901 2.12816 21.9025 2.11892 21.9031 2.11263C21.9044 2.09896 21.9021 2.07888 21.8916 2.05683C21.881 2.03479 21.8668 2.02042 21.8554 2.01285C21.8476 2.0077 21.8335 2 21.8014 2Z"
            fill={colors.activeColor}
          />
          <Path
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            fill={colors.activeColor}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill={colors.activeColor}
          />
          <Path
            d="M6.5 8.21504C6.5 7.91128 6.74624 7.66504 7.05 7.66504H8.15C8.45376 7.66504 8.7 7.91128 8.7 8.21504V11.515C8.7 11.8188 8.45376 12.065 8.15 12.065H7.05C6.74624 12.065 6.5 11.8188 6.5 11.515V8.21504Z"
            fill={colors.colordown}
          />
          <Path
            d="M12 8.21504C12 7.91128 12.2462 7.66504 12.55 7.66504H13.65C13.9538 7.66504 14.2 7.91128 14.2 8.21504V11.515C14.2 11.8188 13.9538 12.065 13.65 12.065H12.55C12.2462 12.065 12 11.8188 12 11.515V8.21504Z"
            fill={colors.colordown}
          />
        </>
      ) : (
        <>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.4267 23.19 7.04258 21.8111 5.08868L23.4068 3.45456L23.4368 3.41739C24.5435 2.04354 23.5655 0 21.8014 0H12.5V0.0102278C12.3342 0.00343142 12.1675 0 12 0ZM18.6356 2C19.3154 2.45201 19.9465 2.97149 20.5193 3.54889L21.8939 2.1412C21.901 2.12816 21.9025 2.11892 21.9031 2.11263C21.9044 2.09896 21.9021 2.07888 21.8916 2.05683C21.881 2.03479 21.8668 2.02042 21.8554 2.01285C21.8476 2.0077 21.8335 2 21.8014 2H18.6356ZM6.5 8.21504C6.5 7.91128 6.74624 7.66504 7.05 7.66504H8.15C8.45376 7.66504 8.7 7.91128 8.7 8.21504V11.515C8.7 11.8188 8.45376 12.065 8.15 12.065H7.05C6.74624 12.065 6.5 11.8188 6.5 11.515V8.21504ZM12.55 7.66504C12.2462 7.66504 12 7.91128 12 8.21504V11.515C12 11.8188 12.2462 12.065 12.55 12.065H13.65C13.9538 12.065 14.2 11.8188 14.2 11.515V8.21504C14.2 7.91128 13.9538 7.66504 13.65 7.66504H12.55Z"
            fill={colors.activeColor}
          />
        </>
      )}
    </SdlSVG>
  )
}

export const NotificationsIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {activeColor: '#292D32', colordown: 'white'}
      : {activeColor: 'white', colordown: 'black'}
  }, [theme])

  return (
    <SdlSVG
      defaultSize={25}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      {active ? (
        <>
          <Defs>
            <ClipPath id="clip0_6436_362778">
              <Rect width="24" height="24" fill={colors.colordown} />
            </ClipPath>
            <ClipPath id="clip1_6436_362778">
              <Rect
                width="21.17"
                height="24"
                fill={colors.colordown}
                transform="translate(1.41016)"
              />
            </ClipPath>
          </Defs>
          <G ClipPath="url(#clip0_6436_362778)">
            <G ClipPath="url(#clip1_6436_362778)">
              <Path
                d="M22.4699 17.74L21.6499 15.29V9.24C21.6499 4.94 18.4499 1.14 14.0299 0.21C12.7099 -0.07 11.1999 -0.07 9.86992 0.21C5.39992 1.15 2.26992 4.86 2.26992 9.24V15.29L1.49992 17.79C1.30992 18.4 1.41992 19.05 1.79992 19.57C2.17992 20.09 2.76992 20.38 3.40992 20.38H8.22992C8.28992 20.97 8.46992 21.52 8.74992 22.01C9.45992 23.2 10.7699 24.01 12.2699 24.01C13.7699 24.01 15.0899 23.21 15.7999 22.01C16.0899 21.52 16.2599 20.97 16.3199 20.38H20.5699C21.2099 20.38 21.8199 20.07 22.1899 19.55C22.5699 19.03 22.6699 18.36 22.4599 17.75L22.4699 17.74ZM12.2099 22.3C11.1299 22.3 10.2599 21.43 10.2199 20.37H14.2099C14.1699 21.44 13.2999 22.3 12.2199 22.3H12.2099Z"
                fill={colors.activeColor}
              />
              <Path
                d="M12.21 4.44043C11.66 4.44043 11.21 4.89043 11.21 5.44043V8.77043C11.21 9.32043 11.66 9.77043 12.21 9.77043C12.76 9.77043 13.21 9.32043 13.21 8.77043V5.44043C13.21 4.89043 12.76 4.44043 12.21 4.44043Z"
                fill={colors.colordown}
              />
            </G>
          </G>
        </>
      ) : (
        <>
          <Defs>
            <ClipPath id="clip0_6436_362781">
              <Rect width="24" height="24" fill="white" />
            </ClipPath>
            <ClipPath id="clip1_6436_362781">
              <Rect
                width="21.17"
                height="24"
                fill="white"
                transform="translate(1.41016)"
              />
            </ClipPath>
          </Defs>
          <G ClipPath="url(#clip0_6436_362781)">
            <G ClipPath="url(#clip1_6436_362781)">
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6499 15.29L22.4699 17.74L22.4599 17.75C22.6699 18.36 22.5699 19.03 22.1899 19.55C21.8199 20.07 21.2099 20.38 20.5699 20.38H16.3199C16.2599 20.97 16.0899 21.52 15.7999 22.01C15.0899 23.21 13.7699 24.01 12.2699 24.01C10.7699 24.01 9.45992 23.2 8.74992 22.01C8.46992 21.52 8.28992 20.97 8.22992 20.38H3.40992C2.76992 20.38 2.17992 20.09 1.79992 19.57C1.41992 19.05 1.30992 18.4 1.49992 17.79L2.26992 15.29V9.24C2.26992 4.86 5.39992 1.15 9.86992 0.21C11.1999 -0.07 12.7099 -0.07 14.0299 0.21C18.4499 1.14 21.6499 4.94 21.6499 9.24V15.29ZM10.2999 2.17C6.74992 2.91 4.26992 5.82 4.26992 9.24V14.44H19.6599V9.24C19.6599 5.88 17.1199 2.9 13.6299 2.17C12.5899 1.95 11.3399 1.95 10.2999 2.17ZM10.2199 20.37C10.2599 21.43 11.1299 22.3 12.2099 22.3H12.2199C13.2999 22.3 14.1699 21.44 14.2099 20.37H10.2199ZM3.99992 16.44L3.40992 18.37H20.5699L19.9799 16.44H3.99992ZM11.21 5.44043C11.21 4.89043 11.66 4.44043 12.21 4.44043C12.76 4.44043 13.21 4.89043 13.21 5.44043V8.77043C13.21 9.32043 12.76 9.77043 12.21 9.77043C11.66 9.77043 11.21 9.32043 11.21 8.77043V5.44043Z"
                fill={colors.activeColor}
              />
            </G>
          </G>
        </>
      )}
    </SdlSVG>
  )
}

export const SearchIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {color: '#292D32', activeColor: 'black', colordown: 'white'}
      : {color: 'white', activeColor: 'white', colordown: 'black'}
  }, [theme])

  if (active) {
    return (
      <SdlSVG
        defaultSize={24}
        size={size}
        style={[
          {transform: [{translateY: 0}, {translateX: 0}]},
          styles.ctrlIcon,
          style,
        ]}>
        <Path
          d="M1.5 11.5C1.5 17.0228 5.97715 21.5 11.5 21.5C17.0228 21.5 21.5 17.0228 21.5 11.5C21.5 5.97715 17.0228 1.5 11.5 1.5C5.97715 1.5 1.5 5.97715 1.5 11.5Z"
          fill={colors.activeColor}
          stroke={colors.activeColor}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M6 11.5C6 8.46243 8.46243 6 11.5 6"
          stroke={colors.colordown}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21.5 21.5L19.5 19.5"
          stroke={colors.activeColor}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SdlSVG>
    )
  }

  return (
    <SdlSVG
      defaultSize={24}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 22.5C5.42487 22.5 0.5 17.5751 0.5 11.5C0.5 5.42487 5.42487 0.5 11.5 0.5C17.5751 0.5 22.5 5.42487 22.5 11.5C22.5 17.5751 17.5751 22.5 11.5 22.5ZM2.5 11.5C2.5 16.4706 6.52944 20.5 11.5 20.5C16.4706 20.5 20.5 16.4706 20.5 11.5C20.5 6.52944 16.4706 2.5 11.5 2.5C6.52944 2.5 2.5 6.52944 2.5 11.5Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7929 18.7929C19.1834 18.4024 19.8166 18.4024 20.2071 18.7929L22.2071 20.7929C22.5976 21.1834 22.5976 21.8166 22.2071 22.2071C21.8166 22.5976 21.1834 22.5976 20.7929 22.2071L18.7929 20.2071C18.4024 19.8166 18.4024 19.1834 18.7929 18.7929Z"
        fill={colors.color}
      />
    </SdlSVG>
  )
}

export const CircleIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {color: '#292D32', activeColor: 'black', colordown: 'white'}
      : {color: 'white', activeColor: 'white', colordown: 'black'}
  }, [theme])

  if (active) {
    return (
      <SdlSVG
        defaultSize={24}
        size={size}
        style={[
          {transform: [{translateY: 0}, {translateX: 0}]},
          styles.ctrlIcon,
          style,
        ]}>
        <Path
          d="M6.05998 1.25C4.21388 1.25 2.72998 2.74768 2.72998 4.58L2.73 4.58536C2.74285 6.38429 4.15889 7.84488 5.94283 7.90951C5.98364 7.91099 6.0245 7.90913 6.06498 7.90396C6.10546 7.90913 6.14632 7.91099 6.18714 7.90951C7.97013 7.84491 9.38998 6.38403 9.38998 4.58C9.38998 2.73579 7.9042 1.25 6.05998 1.25Z"
          fill={colors.color}
        />
        <Path
          d="M14.58 4.58C14.58 2.73579 16.0657 1.25 17.91 1.25C19.7561 1.25 21.24 2.74768 21.24 4.58V4.58536C21.2271 6.38428 19.811 7.84488 18.0271 7.90951C17.9863 7.91099 17.9454 7.90913 17.905 7.90396C17.8645 7.90913 17.8236 7.91099 17.7828 7.90951C15.9998 7.84491 14.58 6.38403 14.58 4.58Z"
          fill={colors.color}
        />
        <Path
          d="M16.8105 8.85124C18.3308 8.58491 20.0644 8.83883 21.3246 9.67506L21.326 9.67596C22.1874 10.2502 22.7174 11.0823 22.7174 12.01C22.7174 12.9377 22.1874 13.7698 21.326 14.344C20.0751 15.1813 18.3623 15.4342 16.8458 15.1796C16.4373 15.1111 16.1617 14.7243 16.2303 14.3158C16.2816 14.0105 16.5106 13.7794 16.7936 13.7108C16.9416 13.1803 16.9933 12.6443 17 12.0004C17 11.3494 16.9661 10.8381 16.8265 10.3316C16.517 10.2843 16.2582 10.0449 16.2012 9.71941C16.1297 9.31141 16.4025 8.92271 16.8105 8.85124Z"
          fill={colors.color}
        />
        <Path
          d="M7.4155 13.8156C7.5819 13.9264 7.70388 14.1031 7.73959 14.3158C7.80817 14.7243 7.53261 15.1111 7.12411 15.1796C5.60735 15.4343 3.8942 15.1812 2.64324 14.3436C1.78225 13.7693 1.25244 12.9374 1.25244 12.01C1.25244 11.0823 1.78253 10.2502 2.64392 9.67596L2.64526 9.67506C3.90551 8.83883 5.63904 8.58491 7.15935 8.85124C7.56736 8.92271 7.84016 9.31141 7.76869 9.71941C7.72673 9.95895 7.57542 10.1519 7.37506 10.2559C7.07114 10.8836 7 11.1016 7 11.9996C7 12.8937 7.10195 13.1336 7.4155 13.8156Z"
          fill={colors.color}
        />
        <Path
          d="M11.91 8.71973C10.0657 8.71973 8.57996 10.2055 8.57996 12.0497C8.57996 13.8538 9.9998 15.3146 11.7828 15.3792C11.8236 15.3807 11.8645 15.3789 11.905 15.3737C11.9436 15.3786 11.9827 15.3805 12.0217 15.3794C13.8147 15.3274 15.2271 13.8492 15.2399 12.0551L15.24 12.0497C15.24 10.2174 13.7561 8.71973 11.91 8.71973Z"
          fill={colors.color}
        />
        <Path
          d="M12.0037 16.2344C13.1722 16.2344 14.381 16.5254 15.326 17.1553C16.1874 17.7296 16.7175 18.5617 16.7175 19.4894C16.7175 20.4169 16.1875 21.2489 15.3263 21.8232C14.3774 22.4576 13.169 22.7519 12 22.7519C10.831 22.7519 9.62258 22.4576 8.67362 21.8232C7.81243 21.2489 7.28247 20.4169 7.28247 19.4894C7.28247 18.5617 7.81256 17.7296 8.67395 17.1553L8.67575 17.1541C9.62473 16.5254 10.835 16.2344 12.0037 16.2344Z"
          fill={colors.color}
        />
      </SdlSVG>
    )
  }

  return (
    <SdlSVG
      defaultSize={24}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9101 2.75C16.8943 2.75 16.0801 3.56421 16.0801 4.58C16.0801 5.55792 16.8322 6.34607 17.7843 6.40782C17.8642 6.40073 17.946 6.40073 18.0258 6.40782C18.9763 6.34613 19.7317 5.559 19.7401 4.57733C19.7386 3.57086 18.9231 2.75 17.9101 2.75ZM14.5801 4.58C14.5801 2.73579 16.0659 1.25 17.9101 1.25C19.7562 1.25 21.2401 2.74768 21.2401 4.58V4.58536H21.2401C21.2272 6.38428 19.8112 7.84488 18.0272 7.90951C17.9864 7.91099 17.9456 7.90913 17.9051 7.90396C17.8646 7.90913 17.8237 7.91099 17.7829 7.90951C15.9999 7.84491 14.5801 6.38403 14.5801 4.58Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8106 8.85124C18.331 8.58491 20.0645 8.83883 21.3247 9.67506L21.3261 9.67596C22.1875 10.2502 22.7176 11.0823 22.7176 12.01C22.7176 12.9377 22.1875 13.7698 21.3261 14.344C20.0752 15.1813 18.3624 15.4342 16.8459 15.1796C16.4374 15.1111 16.1618 14.7243 16.2304 14.3158C16.299 13.9073 16.6857 13.6318 17.0942 13.7003C18.3172 13.9057 19.6237 13.6789 20.4927 13.0969L20.494 13.096C21.0427 12.7302 21.2176 12.3223 21.2176 12.01C21.2176 11.6978 21.0428 11.2901 20.4947 10.9245C19.615 10.3411 18.2889 10.1151 17.0695 10.3287C16.6615 10.4002 16.2728 10.1274 16.2013 9.71941C16.1298 9.31141 16.4026 8.92271 16.8106 8.85124Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72998 4.58C2.72998 2.74768 4.21388 1.25 6.05998 1.25C7.9042 1.25 9.38998 2.73579 9.38998 4.58C9.38998 6.38403 7.97013 7.84491 6.18714 7.90951C6.14632 7.91099 6.10547 7.90913 6.06498 7.90396C6.0245 7.90913 5.98364 7.91099 5.94283 7.90951C4.15889 7.84488 2.74285 6.38429 2.73 4.58536L2.72998 4.58ZM4.22998 4.57733C4.23836 5.559 4.99376 6.34613 5.94423 6.40782C6.02409 6.40073 6.10588 6.40073 6.18574 6.40782C7.13785 6.34607 7.88998 5.55792 7.88998 4.58C7.88998 3.56421 7.07577 2.75 6.05998 2.75C5.04698 2.75 4.23142 3.57087 4.22998 4.57733Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47529 10.9245C4.35505 10.3411 5.68115 10.1151 6.90052 10.3287C7.30852 10.4002 7.69721 10.1274 7.76869 9.71941C7.84016 9.31141 7.56736 8.92271 7.15935 8.85124C5.63904 8.58491 3.90551 8.83883 2.64527 9.67506L2.64526 9.67506L2.64392 9.67596C1.78253 10.2502 1.25244 11.0823 1.25244 12.01C1.25244 12.9374 1.78225 13.7693 2.64324 14.3436C3.8942 15.1812 5.60735 15.4343 7.12411 15.1796C7.53261 15.1111 7.80817 14.7243 7.73959 14.3158C7.67101 13.9073 7.28426 13.6318 6.87577 13.7003C5.6528 13.9057 4.3463 13.6789 3.47732 13.0969L3.47597 13.096C2.92735 12.7302 2.75244 12.3223 2.75244 12.01C2.75244 11.6978 2.92721 11.2901 3.47529 10.9245Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9101 10.22C10.8943 10.22 10.0801 11.0342 10.0801 12.05C10.0801 13.0279 10.8322 13.816 11.7843 13.8778C11.8653 13.8706 11.9483 13.8707 12.0292 13.8781C12.972 13.824 13.7317 13.0343 13.7401 12.0473C13.7386 11.0408 12.9231 10.22 11.9101 10.22ZM8.58008 12.05C8.58008 10.2058 10.0659 8.71997 11.9101 8.71997C13.7562 8.71997 15.2401 10.2177 15.2401 12.05V12.0553H15.2401C15.2272 13.8494 13.8148 15.3277 12.0218 15.3797C11.9828 15.3808 11.9438 15.3789 11.9051 15.3739C11.8646 15.3791 11.8237 15.381 11.7829 15.3795C9.99992 15.3149 8.58008 13.854 8.58008 12.05Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0037 16.2349C13.1722 16.2349 14.381 16.5259 15.326 17.1558C16.1874 17.7301 16.7175 18.5622 16.7175 19.4899C16.7175 20.4174 16.1875 21.2494 15.3263 21.8237C14.3774 22.4581 13.169 22.7524 12 22.7524C10.831 22.7524 9.62258 22.4581 8.67362 21.8237C7.81243 21.2494 7.28247 20.4174 7.28247 19.4899C7.28247 18.5622 7.81256 17.7301 8.67395 17.1558L8.67575 17.1546L8.67575 17.1546C9.62473 16.5259 10.835 16.2349 12.0037 16.2349ZM9.50511 18.4045C8.95719 18.7701 8.78247 19.1777 8.78247 19.4899C8.78247 19.8022 8.95738 20.2101 9.506 20.5758L9.5069 20.5764C10.1578 21.0117 11.0592 21.2524 12 21.2524C12.9407 21.2524 13.8421 21.0117 14.493 20.5764L14.4939 20.5758C15.0426 20.2101 15.2175 19.8022 15.2175 19.4899C15.2175 19.1775 15.0426 18.7696 14.4939 18.4039C13.8489 17.9739 12.9477 17.7349 12.0037 17.7349C11.0604 17.7349 10.1561 17.9736 9.50511 18.4045Z"
        fill={colors.color}
      />
    </SdlSVG>
  )
}

export const BottomBarBG = () => {
  const theme = useThemeName()

  const colors = useMemo(() => {
    const bgColor = getBackgroundColor(theme)
    return theme === 'light'
      ? {line: '#E8E8E8', bg: bgColor}
      : {line: '#25253A', bg: bgColor}
  }, [theme])

  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}>
      <View
        style={{
          marginTop: 16,
          height: 107,
          width: '100%',
          borderTopWidth: 1,
          borderColor: colors.line,
          backgroundColor: colors.bg,
        }}
      />
      <Svg
        width={375}
        height={107}
        viewBox="0 0 375 107"
        fill="none"
        style={{
          position: 'absolute',
          left: '50%',
          top: 0,
          transform: 'translate(-50%, 0%)',
        }}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M375 16H224.563C219.139 16 214.238 12.805 210.085 8.84315C204.343 3.36515 196.564 0 188 0C179.436 0 171.657 3.36515 165.915 8.84315C161.762 12.805 156.861 16 151.437 16H0V17H151.437C157.254 17 162.396 13.5825 166.605 9.5667C172.168 4.25903 179.704 1 188 1C196.296 1 203.832 4.25903 209.395 9.5667C213.604 13.5825 218.746 17 224.563 17H375V16Z"
          fill={colors.line}
        />
      </Svg>
      <Svg
        width={375}
        height={107}
        viewBox="0 0 375 107"
        fill="none"
        style={{
          position: 'absolute',
          left: '50%',
          top: 0,
          transform: 'translate(-50%, 0%)',
        }}>
        <Path
          d="M209.395 9.5667C213.604 13.5825 218.746 17 224.563 17H375V107H0V17H151.437C157.254 17 162.396 13.5825 166.605 9.5667C172.168 4.25903 179.704 1 188 1C196.296 1 203.832 4.25903 209.395 9.5667Z"
          fill={colors.bg}
        />
      </Svg>
    </View>
  )
}

export const InstallGuide = () => {
  const t = useTheme()
  const [show, setShow] = React.useState(true)
  const index = localStorage.getItem(Install_Guide) || 0
  useEffect(() => {
    if (+index >= 3) {
      setShow(false)
    }
  }, [index])
  if (navigator?.standalone === false && +index < 3 && show) {
    return (
      <View
        style={{
          flex: 1,
          position: isMobileWeb ? 'fixed' : 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          borderRadius: 16,
          zIndex: 9999,
          transform: 'scale(0.95)',
        }}>
        <Image
          style={{
            position: isMobileWeb ? 'fixed' : 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            borderRadius: 16,
            zIndex: 9999,
            transform: 'scale(0.95)',
          }}
          accessibilityIgnoresInvertColors
          source={{uri: require('../../../assets/install-ios.png')}}
        />
        <Button
          style={{
            position: 'absolute',
            right: 20,
            top: 29,
            zIndex: 10000,
          }}
          label="clear"
          onPress={() => {
            localStorage.setItem(Install_Guide, `${+index + 1}`)
            setShow(false)
          }}>
          <IconCircle
            style={[{width: 16, height: 16, backgroundColor: t.palette.black}]}
            icon={Close}
            iconStyle={{color: t.palette.white}}
            size="xs"
          />
        </Button>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  ctrlIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
