import React from 'react'
import {View, ViewStyle} from 'react-native'

// import {TabBar} from '#/view/com/pager/TabBar'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {atoms as a} from '#/alf'
import {Loader} from '#/components/Loader'
import {SmallContact} from '../Telegram/ContactItem'
import ContactList from '../Telegram/ContactList'
import {ContactItemProps} from '.'
import {removeDuplicatesWithSet, useTelegramContactList} from './hooks'

type Props = {
  circleId: number
  selected?: ContactItemProps[]
  historyMembers?: ContactItemProps[]
  onSelected?: (selected: ContactItemProps[]) => void
  style?: ViewStyle
}
export function SelectFriend({
  selected = [],
  historyMembers,
  onSelected,
  style,
}: Props) {
  // const t = useTheme()
  const [query, setQuery] = React.useState('')
  // const items = ['Telegram Contact']
  // const [tabIndex, setTabIndex] = React.useState(0)
  const {data, isFetching} = useTelegramContactList({circleId: -1, query})

  const contacts = React.useMemo(() => {
    if (data) {
      return removeDuplicatesWithSet(data, 'id')
    }
    return []
  }, [data])

  const onSubmitQuery = () => {}
  const onPressCancelSearch = () => {
    setQuery('')
  }

  const handleSelected = (isSelected: boolean, _item: ContactItemProps) => {
    let list: ContactItemProps[] = selected
    if (!isSelected) {
      list = selected.filter(item => item.id !== _item.id)
    } else {
      list = [...selected, _item]
    }
    onSelected?.(list)
  }

  let content = <EmptyBox icon="heart" message="has no contacts yet." />
  if (isFetching) {
    content = (
      <View style={[a.align_center, a.justify_center, a.p_5xl]}>
        <Loader size="lg" />
      </View>
    )
  } else if (contacts.length) {
    content = (
      <ContactList
        selected={selected}
        users={contacts}
        historyMembers={historyMembers}
        onSelected={handleSelected}
      />
    )
  }

  return (
    <View style={[a.p_xl, {paddingBottom: 100}, style]}>
      <SearchInput
        query={query}
        onSubmitQuery={onSubmitQuery}
        onChangeQuery={setQuery}
        onPressCancelSearch={onPressCancelSearch}
      />
      {/* <TabBar
        isBold={false}
        hideBorder={true}
        items={items}
        bgColor={a.bg_transparent.backgroundColor}
        selectedPage={tabIndex}
        onSelect={setTabIndex}
        textStyle={[a.text_md, a.font_normal, t.atoms.text]}
        selectedTextStyle={[a.text_md, a.font_heavy, t.atoms.text]}
      /> */}
      <View
        style={[
          a.flex_row,
          a.pt_lg,
          a.pb_lg,
          a.flex_wrap,
          a.gap_sm,
          {marginTop: 5},
        ]}>
        {selected.map((contact: ContactItemProps) => {
          const disabled = !!historyMembers?.find(
            _item => _item.id === contact.id,
          )

          return (
            <SmallContact
              key={contact.id}
              user={contact}
              disabled={disabled}
              onSelected={handleSelected}
            />
          )
        })}
      </View>
      {content}
    </View>
  )
}
