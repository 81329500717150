export function fuzzyMatch(matchText: string, searchInput: string): boolean {
  if (!searchInput) return false
  const lowerMatchText = matchText.toLowerCase()
  const lowerSearchInput = searchInput.toLowerCase()
  let matchIndex = 0
  let searchInputIndex = 0

  while (
    matchIndex < lowerMatchText.length &&
    searchInputIndex < lowerSearchInput.length
  ) {
    if (lowerMatchText[matchIndex] === lowerSearchInput[searchInputIndex]) {
      searchInputIndex++
    }
    matchIndex++
  }
  return searchInputIndex === lowerSearchInput.length
}
