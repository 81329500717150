import React, {useState} from 'react'
import {ScrollView, View} from 'react-native'

import {useTagList} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {FilterNormal_Stroke2_Corner0_Rounded as FilterNormal} from '#/components/icons/FilterNormal'
import {H3} from '#/components/Typography'
import {EmptyBox} from '../util/EmptyBox'
import TagItem, {Tag} from './TagItem'

type Props = {
  defaultDisplayTags?: Tag[]
  horizontal?: boolean
  onSelected?: (tag: Tag) => void
  onConfirm?: (tag: Tag[]) => void
}

export default function TagFilter({
  defaultDisplayTags,
  horizontal = true,
  onSelected,
  onConfirm,
}: Props) {
  const t = useTheme()
  const basic = Dialog.useDialogControl()
  // const [tags, setTags] = useState<Tag[]>([])
  const {data: tags = []} = useTagList()
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])

  const selectedHandle = (selected: boolean, tag: Tag) => {
    if (selected) {
      setSelectedTags(s => [...s, tag])
    } else {
      setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
    }
  }

  let content
  if (tags.length === 0) {
    content = (
      <View style={[a.align_center]}>
        <EmptyBox icon="notifications" message={'there is no tags'} />
      </View>
    )
  } else {
    content = (
      <View style={[{gap: 18}]}>
        {tags.map(tag => (
          <TagItem
            key={tag.name}
            tag={tag}
            showCheckbox
            selected={!!selectedTags.find(item => item.name === tag.name)}
            onSelected={selectedHandle}
          />
        ))}
      </View>
    )
  }

  return (
    <>
      <View style={[a.flex_row, a.align_center, a.py_md]}>
        <ScrollView horizontal={horizontal}>
          <View style={[a.flex_row, {gap: 8}]}>
            {defaultDisplayTags?.map(t => (
              <TagItem key={t.id} tag={t} onSelected={() => onSelected?.(t)} />
            ))}
          </View>
        </ScrollView>
        <Button
          label="filter"
          style={[a.align_center, a.justify_center]}
          onPress={() => basic.open()}>
          <FilterNormal
            style={[{marginLeft: 'auto', color: t.atoms.text.color}]}
          />
        </Button>
      </View>
      <Dialog.Outer control={basic}>
        <Dialog.Inner label="test" style={{minHeight: 360}}>
          <View
            style={[a.flex_row, a.align_center, a.justify_between, a.pb_3xl]}>
            <Button
              label={'close'}
              style={[a.p_sm]}
              onPress={() => basic.close()}>
              <Close fill={t.atoms.text.color} />
              {/* <ButtonIcon icon={Close} /> */}
            </Button>
            <H3
              nativeID="dialog-title"
              style={[a.font_semibold, a.text_center, {fontSize: 17}]}>
              Tag Filter
            </H3>
            {/* <Button
              style={[a.p_md, {width: 50}]}
              color="secondary"
              onPress={() => setCreateStatus('open')}
              label="Create"
              variant="solid"
              shape="square"
              size="tiny">
              <ButtonText>Create</ButtonText>
            </Button> */}
            <View />
          </View>
          {content}
          {selectedTags.length > 0 && (
            <View style={[{gap: 24}, a.mt_2xl]}>
              <Button
                style={[a.p_md]}
                color="primary"
                onPress={() => {
                  onConfirm?.(selectedTags)
                  basic.close()
                }}
                label="Confirm"
                variant="solid"
                size="large">
                <ButtonText>Confirm</ButtonText>
              </Button>
              <Button
                style={[a.p_md]}
                color="secondary"
                onPress={() => {
                  onConfirm?.([])
                  setSelectedTags([])
                  basic.close()
                }}
                label="reset"
                variant="solid"
                size="large">
                <ButtonText>Reset</ButtonText>
              </Button>
            </View>
          )}
        </Dialog.Inner>
      </Dialog.Outer>
      {/* <TagCreate status={createStatus} updateTags={updateTags} /> */}
    </>
  )
}
