import React from 'react'
import {View} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {useQueryClient} from '@tanstack/react-query'

import {isMobileWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useTelegramLink} from '#/view/com/modals/TelegramModal/hooks'
import {TabBar} from '#/view/com/pager/TabBar'
import {Tag} from '#/view/com/tags/TagItem'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {SimpleViewHeader} from '#/view/com/util/SimpleViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {Refresh} from '#/components/icons/Refresh'
import {Loader} from '#/components/Loader'
import {CirclePanel} from './CirclePanel'
import {FriendPanel} from './FriendPanel'
import {telegramContactList} from './hooks'

export type MemberProps = {
  id: string
  name: string
  avatar?: string
}

export type CircleItemProps = {
  id: string
  name?: string
  bio?: string
  private?: boolean
  isOwner?: boolean
  banner?: string
  avatar?: string
  members?: MemberProps[]
  memberCount?: number
  createBy?: 'system' | 'user'
  creator?: string
}

export type CircleItemParams = {
  circle: CircleItemProps
  newCircleAvatar?: RNImage
}

export type CircleMemberPrams = {
  id: string
  from: 'telegram' | 'twitter' | 'sipz'
}

export type CircleMembersParams = {
  circleId: string
  members?: CircleMemberPrams[]
}

export type ContactItemProps = {
  id: string
  isMutual?: boolean
  username?: string
  nickname?: string
  avatar?: string
  tags?: Tag[]
  from?: 'telegram' | 'twitter' | 'sipz'
}

const tabPanels = [
  (query: string) => <FriendPanel key="FriendPanel" query={query} />,
  (query: string) => <CirclePanel key="CirclePanel" query={query} />,
]

export default function Circle() {
  const [query, setQuery] = React.useState('')
  const t = useTheme()
  const {saveContacts} = useTelegramLink()
  const queryClient = useQueryClient()
  const {openModal} = useModalControls()
  const [selected, setSelected] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const items = ['Contact', 'Circle']
  const tabPanel = React.useMemo(() => {
    return tabPanels[selected]
  }, [selected])

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const reloadTelegramContacts = React.useCallback(async () => {
    if (!loading) {
      try {
        setLoading(true)
        await saveContacts()
        queryClient.invalidateQueries({queryKey: telegramContactList})
      } catch (err: any) {
        if (err?.code === 401) {
          openModal({name: 'telegram'})
        }
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }, [loading, openModal, queryClient, saveContacts])

  const onSubmitQuery = () => {
    queryClient.invalidateQueries({queryKey: [telegramContactList, {query}]})
  }

  return (
    <CenteredView>
      <SimpleViewHeader showBackButton={isMobileWeb ?? false}>
        <SearchInput
          query={query}
          onChangeQuery={onChangeQuery}
          onPressCancelSearch={onPressCancelSearch}
          onSubmitQuery={onSubmitQuery}
          style={[a.border, t.atoms.input_bg, t.atoms.input_border]}
        />
      </SimpleViewHeader>
      <TabBar
        hideBorder={true}
        isBold={false}
        items={items}
        style={[a.pl_lg, a.pr_lg]}
        selectedPage={selected}
        textStyle={[a.text_md, a.font_normal, t.atoms.text]}
        selectedTextStyle={[a.text_md, a.font_heavy, t.atoms.text]}
        onSelect={(index: number) => {
          setSelected(index)
          setQuery('')
        }}
        rightView={
          selected === 0 ? (
            <Button label="refresh" onPress={reloadTelegramContacts}>
              <Refresh width={24} height={24} style={[t.atoms.text]} />
            </Button>
          ) : undefined
        }
      />

      {loading ? (
        <View style={[a.align_center, a.justify_center, a.p_2xl]}>
          <Loader size="xl" />
        </View>
      ) : (
        tabPanel(query)
      )}
    </CenteredView>
  )
}
