import React, {useEffect} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {isMobileWeb} from '#/platform/detection'
import TagFilter from '#/view/com/tags/TagFilter'
import {SimpleTag, Tag} from '#/view/com/tags/TagItem'
import TagList from '#/view/com/tags/TagList'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {useDialogControl} from '#/components/Dialog'
import {Loader} from '#/components/Loader'
import ContactItem from '../Telegram/ContactItem'
import ContactList from '../Telegram/ContactList'
import {ContactItemProps} from '.'
import {
  removeDuplicatesWithSet,
  telegramContactList,
  useTagList,
  useTagUpdateMutation,
  useTelegramContactList,
  useUpdateUserTagsMutation,
} from './hooks'

interface IProps {
  query?: string
}

const defaultTags: Tag[] = [
  {id: 1, name: 'Telegram'},
  // {id: 2, name: 'Sipz'},
  // {id: 3, name: 'X'},
]

export function FriendPanel({query}: IProps) {
  const t = useTheme()

  const queryClient = useQueryClient()
  const control = useDialogControl()
  const tagUpdateControl = useDialogControl()
  const tagUpdate = useTagUpdateMutation()
  const updateUserTags = useUpdateUserTagsMutation()

  const {_} = useLingui()
  const [selected, setSelected] = React.useState<ContactItemProps>()
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([])
  const [filterTags, setFilterTags] = React.useState<Tag[]>([])
  const [editTag, setEditTag] = React.useState<Tag>({id: -1, name: ''})
  const {status, data, error} = useTelegramContactList({
    circleId: -1,
    query,
    tags: filterTags,
  })
  const {data: tags = []} = useTagList()

  const onClickItem = (user: ContactItemProps) => {
    control.open()
    setSelected(user)
    setSelectedTags(user?.tags || [])
  }

  const onSelectTag = (_selected: boolean, tag: Tag) => {
    if (_selected) {
      setSelectedTags(prev => [...prev, tag])
    } else {
      setSelectedTags(prev => prev.filter(item => item.id !== tag.id))
    }
  }

  const onClose = () => {
    setSelectedTags([])
  }

  const onTagCreateAndUpdate = async () => {
    if (!editTag?.name) {
      return
    }
    await tagUpdate.mutateAsync({
      id: editTag?.id,
      name: editTag?.name,
    })
    tagUpdateControl.close()
  }

  const onPressSelectTags = async () => {
    if (!selected?.id) {
      return
    }
    await updateUserTags.mutateAsync({
      userId: selected?.id,
      tags: selectedTags,
    })

    control.close()
  }

  const onConfirmFilterTags = (_tags: Tag[]) => {
    setFilterTags(_tags)
    queryClient.invalidateQueries({
      queryKey: [telegramContactList, {tags: _tags}],
    })
  }

  let content
  if (status === 'pending') {
    content = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (error) {
    content = <View>{error.message}</View>
  } else if (data?.length === 0) {
    content = <EmptyBox icon="tea" message={'there is no contacts'} />
  } else {
    content = (
      <ContactList
        users={removeDuplicatesWithSet(data, 'id')}
        showCheckbox={false}
        // itemType="menu"
        onClickItem={onClickItem}
        filterTags={filterTags}
        // onSelected={selectHandle}
        // selected={selectedItems}
      />
    )
  }
  // return <View>{content}</View>

  return (
    <View style={[a.px_xl, {paddingBottom: isMobileWeb ? 90 : 0}]}>
      <TagFilter
        defaultDisplayTags={defaultTags}
        onConfirm={onConfirmFilterTags}
      />
      {content}
      <Dialog.Outer control={control}>
        {/* <Dialog.Handle /> */}
        <Dialog.ScrollableInner
          label={_(msg`New user info dialog`)}
          style={[a.w_full]}>
          <View style={[a.flex_col, a.gap_md]}>
            {selected && <ContactItem user={selected} showTag={false} />}
            <View style={[a.flex_row, a.flex_wrap, a.gap_sm]}>
              {selectedTags &&
                selectedTags.map(_tag => {
                  return (
                    <SimpleTag
                      key={_tag.name}
                      tag={_tag}
                      style={{height: 24}}
                    />
                  )
                })}
              <Button
                color="secondary"
                variant="solid"
                size="xsmall"
                label="Create tags"
                onPress={() => {
                  setEditTag({
                    id: -1,
                    name: '',
                  })
                  tagUpdateControl.open()
                }}
                style={[
                  a.rounded_xs,
                  {backgroundColor: '#FCD01059', height: 24},
                ]}>
                <FontAwesomeIcon icon="plus" style={{color: '#C28C00'}} />
                <Text style={[{color: '#C28C00', fontSize: 12}]}>
                  <Trans>Create tags</Trans>
                </Text>
              </Button>
            </View>
          </View>
          <View style={[styles.useTags]} />
          <TagList
            showCheckbox
            tags={tags}
            selected={selectedTags}
            onSelected={onSelectTag}
          />
          <Button
            color="primary"
            variant="solid"
            size="medium"
            label="Confirm"
            onPress={onPressSelectTags}
            style={[a.mt_3xl]}>
            <Text>
              <Trans>Confirm</Trans>
            </Text>
            {updateUserTags.isPending && (
              <Loader size="sm" style={[t.atoms.text]} />
            )}
          </Button>
          <Button
            style={[t.atoms.bg_btn_default, a.mt_md]}
            variant="solid"
            size="medium"
            label="Cancel"
            onPress={() => {
              control.close()
              onClose()
            }}>
            <Text style={[t.atoms.text_sub]}>
              <Trans>Cancel</Trans>
            </Text>
          </Button>

          {/* <Dialog.Close /> */}
        </Dialog.ScrollableInner>
      </Dialog.Outer>
      <Dialog.Outer control={tagUpdateControl}>
        <Dialog.Handle />

        <Dialog.Inner label="Create tags">
          <View style={[a.p_md, a.justify_center, a.align_center, a.mb_3xl]}>
            <Text style={[{fontSize: 19, fontWeight: 600}, t.atoms.text]}>
              <Trans>Create tag</Trans>
            </Text>
          </View>

          <InputShadow publishEdiTag={setEditTag} />
          <Button
            color="primary"
            variant="solid"
            size="medium"
            label="Confirm"
            onPress={onTagCreateAndUpdate}
            style={[a.mt_3xl]}>
            <Text>
              <Trans>Confirm</Trans>
            </Text>
            {tagUpdate.isPending && <Loader size="sm" style={[t.atoms.text]} />}
          </Button>
          <Button
            color="secondary"
            variant="solid"
            size="medium"
            label="Cancel"
            style={[a.mt_md]}
            onPress={() => tagUpdateControl.close()}>
            <Text>
              <Trans>Cancel</Trans>
            </Text>
          </Button>
          <Dialog.Close />
        </Dialog.Inner>
      </Dialog.Outer>
    </View>
  )
}

const styles = StyleSheet.create({
  useTags: {
    marginTop: 20,
  },
})

const InputShadow = ({
  publishEdiTag,
}: {
  publishEdiTag: React.Dispatch<React.SetStateAction<Tag>>
}) => {
  const [editTag, setEditTag] = React.useState<Tag>({id: -1, name: ''})
  useEffect(() => {
    publishEdiTag(editTag)
  }, [editTag, publishEdiTag])

  return (
    <Dialog.Input
      value={editTag?.name}
      onChangeText={(value: string) => {
        setEditTag({id: editTag?.id, name: value})
      }}
      label="Type here"
    />
  )
}
