import React, {useState} from 'react'
import {ListRenderItemInfo, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

// import {useSubAccounts} from '#/lib/hooks/useIsMe'
import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {
  CommonNavigatorParams,
  NativeStackScreenProps,
  NavigationProp,
} from '#/lib/routes/types'
import {
  SessionAccount,
  useAgent,
  useSession,
  useSessionApi,
} from '#/state/session'
import {maskToSessionAccount} from '#/state/session/agent'
import {List} from '#/view/com/util/List'
import * as Toast from '#/view/com/util/Toast'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
// import {ScrollView} from '#/view/com/util/Views'
import {MaskEmptyIcon} from '#/view/icons/EmptyIcons'
// import {SvgComponent as MaskIcon} from '#/view/icons/Mask'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {useDialogControl} from '#/components/Dialog'
import * as Prompt from '#/components/Prompt'
import {MaskItem} from './MaskItem'

export type MaskAccount = SessionAccount & {
  name?: string
  avatar?: string
  index?: number
}

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Mask'>

export const Mask = ({route}: Props) => {
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const agent = useAgent()
  const {currentAccount, accounts} = useSession()
  const {resumeSession} = useSessionApi()
  const prompt = useDialogControl()
  const {isTabletOrDesktop} = useWebMediaQueries()
  const navigation = useNavigation<NavigationProp>()
  const [masks, setMasks] = useState<MaskAccount[]>()
  const refPromise = React.useRef<(value: unknown) => void>()

  React.useEffect(() => {
    if (currentAccount) {
      agent.com.atproto.server.getSubAccountLIst().then(res => {
        if (res.success) {
          const list =
            res.data.subAccounts?.map(account => {
              return {
                ...account,
                handle: account.handle,
                avatar: account.avatar,
                service: currentAccount?.service,
              } as MaskAccount
            }) || []
          setMasks(list || [])
        }
      })
    }
  }, [accounts, agent.com.atproto.server, currentAccount, route?.params?.time])

  const onCreateMask = React.useCallback(async () => {
    const canCreate = !!currentAccount?.mnemonic
    if (canCreate) {
      navigation.navigate('SavedMask')
      return
    }
    const promise = new Promise(resolve => {
      prompt.open()
      refPromise.current = resolve
    })
    await promise
  }, [currentAccount?.mnemonic, navigation, prompt])

  const renderHeaderBtn = React.useCallback(() => {
    return (
      <Button
        size="small"
        variant="solid"
        color="primary"
        label="Create"
        onPress={onCreateMask}>
        <Text>Create</Text>
      </Button>
    )
  }, [onCreateMask])

  const onChangeMask = async (item: MaskAccount, selected: boolean) => {
    let handle
    if (selected) {
      const res = await agent.com.atproto.server.createSubAccountSession({
        did: item.did,
      })
      const account = maskToSessionAccount(res.data, agent)
      account.parentDid = item.parentDid
      handle = account.handle
      console.log('account', account)
      await resumeSession(account, true)
    } else {
      const {parentDid} = item
      if (parentDid) {
        const account = accounts.find(a => a.did === parentDid)
        if (account) {
          handle = account.handle
          await resumeSession(account, true)
        }
      }
    }
    if (handle) {
      Toast.show(_(`You are now acting as ${formatHandler(handle)}`))
    }
    // navigation.navigate('Mask', {time: Date.now()})
  }

  const renderItem = ({item, index}: ListRenderItemInfo<MaskAccount>) => {
    console.log(item)
    return (
      <MaskItem
        key={item.did}
        mask={item}
        index={index}
        onChange={onChangeMask}
      />
    )
  }

  const keyExtractor = (item: MaskAccount) => {
    // const selected = item.did === currentAccount?.did ? 'selected' : '';
    return item.did
  }

  return (
    <View style={[pal.view, a.flex_1]}>
      <ViewHeader
        title={_(msg`Manage Mask`)}
        canGoBack
        showBorder
        showOnDesktop
        renderButton={renderHeaderBtn}
        showHorizontalBorder
      />
      <CenteredView
        style={[
          // styles.header,
          a.flex_1,
          pal.border,
          pal.view,
          // isTabletOrDesktop && {paddingTop: 10},
        ]}
        sideBorders={masks?.length === 0 ? isTabletOrDesktop : false}>
        {masks && masks?.length > 0 && (
          // <ScrollView>
          <View
            style={[
              a.flex_1,
              pal.border,
              {borderLeftWidth: 1, borderRightWidth: 1},
            ]}>
            <List
              testID="maskList"
              data={masks}
              style={[a.pl_lg, a.pr_lg, {marginBottom: 100}]}
              sideBorders={false}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
            />
          </View>
          // </ScrollView>
        )}

        {masks?.length === 0 && (
          <View
            style={[
              a.flex_1,
              a.justify_center,
              a.align_center,
              // a.p_lg,
              {marginBottom: 150},
            ]}>
            <MaskEmptyIcon />
            <Text
              style={[
                a.text_xl,
                a.text_center,
                a.text_md,
                // {color: t.palette.black_25},
                a.mb_lg,
                t.atoms.text_sub,
              ]}>
              You can choose one mask for better expressing.
            </Text>
            <Button
              size="small"
              variant="solid"
              color="primary"
              label="Create your mask"
              onPress={() => {
                if (currentAccount?.mnemonic) {
                  navigation.navigate('SavedMask')
                }
              }}>
              <FontAwesomeIcon icon="plus" size={16} />
              <Text
                style={[a.text_md, a.text_center, {color: t.palette.black}]}>
                <Trans>Create</Trans>
              </Text>
            </Button>
          </View>
        )}
      </CenteredView>
      {/* <View style={[a.flex_2, {height: 50}]} /> */}
      <Prompt.Outer control={prompt}>
        <Prompt.TitleText>Switch & Create</Prompt.TitleText>
        <Prompt.DescriptionText>
          Creating a mask requires switching to your main account
        </Prompt.DescriptionText>
        <Prompt.Actions>
          <Prompt.Action
            cta="Confirm"
            onPress={async () => {
              const account = accounts.find(
                a => a.did === currentAccount?.parentDid,
              )
              if (account) {
                navigation.navigate('SavedMask')
                await resumeSession(account)
                refPromise.current?.(true)
              }
            }}
          />
          <Prompt.Cancel />
        </Prompt.Actions>
      </Prompt.Outer>
    </View>
  )
}
