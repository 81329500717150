import React from 'react'
import {Text, View, ViewStyle} from 'react-native'
import {Trans} from '@lingui/macro'

import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Toggle from '#/components/forms/Toggle'
import {IconCircle} from '#/components/IconCircle'
import {PencilLine_Stroke2_Corner0_Rounded as Pencil} from '#/components/icons/Pencil'

export type Tag = {
  id: number
  name: string
  selected?: boolean
}

type Props = {
  selected?: boolean
  tag: Tag
  showEditor?: boolean
  showCheckbox?: boolean
  onSelected?: (selected: boolean, tag: Tag) => void
}
export default function TagItem({
  selected,
  tag,
  showCheckbox,
  showEditor,
  onSelected,
}: Props) {
  const t = useTheme()
  const values = selected ? [tag.name] : []

  const handleSelected = (value: Tag) => {
    onSelected?.(!selected, value)
  }

  return (
    <Button
      style={[
        a.border,
        a.flex_row,
        a.justify_start,
        a.align_center,
        a.rounded_full,
        {borderColor: t.atoms.text_sub.color},
      ]}
      variant="outline"
      size="small"
      label="follower"
      onPress={() => handleSelected?.(tag)}>
      <View style={[a.flex_1, a.flex_row, a.align_center, a.gap_md]}>
        {showEditor && (
          <IconCircle
            style={{
              width: 24,
              height: 24,
              backgroundColor: t.palette.contrast_100,
            }}
            icon={Pencil}
            size="sm"
            iconStyle={[{color: t.palette.contrast_700}]}
          />
        )}
        <Text style={[t.atoms.text_sub]}>
          <Trans>{tag.name}</Trans>
        </Text>
      </View>
      {showCheckbox && (
        <Toggle.Group
          type="checkbox"
          label="Tag"
          values={values}
          onChange={() => handleSelected(tag)}>
          <Toggle.Item name={`${tag.name}`} label="Switch Mask">
            <Toggle.Checkbox />
          </Toggle.Item>
        </Toggle.Group>
      )}
    </Button>
  )
}

type SimpleTagProps = {
  tag: Tag
  isFilter?: boolean
  style?: ViewStyle
}
export const SimpleTag = ({tag, isFilter, style}: SimpleTagProps) => {
  const t = useTheme()
  return (
    <View
      key={tag.name}
      style={[
        a.rounded_xs,
        a.p_sm,
        a.justify_center,
        a.align_center,
        {
          backgroundColor: isFilter
            ? '#8D8D92'
            : t.atoms.bg_btn_default.backgroundColor,
        },
        style,
      ]}>
      <Text
        style={[
          {fontSize: 12},
          {color: isFilter ? '#ffffff' : t.atoms.text_sub.color},
        ]}>
        {tag.name}
      </Text>
    </View>
  )
}
