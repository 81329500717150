import React from 'react'
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import {Route, TabView} from 'react-native-tab-view'
import {AppBskyActorDefs as ActorDefs} from '@atproto/api'
import {Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {isWeb} from '#/platform/detection'
import {useActorAutocompleteQuery} from '#/state/queries/actor-autocomplete'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {SdlInput} from '../../composer/text-input/SdlTextInput'
// import * as TextField from '#/components/forms/TextField'
// import {At_Stroke2_Corner0_Rounded as At} from '#/components/icons/At'
import {Actor} from './Actor'
import {Followers} from './Followers'
import {Following} from './Following'

export type ItemProps = {
  onPress: (item: ActorDefs.ProfileViewBasic) => void
  selecteds?: ActorDefs.ProfileViewBasic[]
}

type Props = {
  actors?: ActorDefs.ProfileViewBasic[]
  control: Dialog.DialogControlProps
  onDone?: (actors: ActorDefs.ProfileViewBasic[]) => void
}
export function PostGuarantor({control, actors, onDone}: Props) {
  const t = useTheme()
  const {_} = useLingui()
  const [keyword, setKeyword] = React.useState('')
  const layout = useWindowDimensions()
  const [selected, setSelected] = React.useState<ActorDefs.ProfileViewBasic[]>(
    actors || [],
  )

  const [index, setIndex] = React.useState(0)
  const [routes] = React.useState([
    {key: 'Following', title: _('Followings')},
    {key: 'Followers', title: _('Followers')},
  ])

  const renderTabBar = (props: any) => {
    return (
      <View style={styles.tabBar}>
        {props!.navigationState.routes.map((route: Route, i: number) => {
          return (
            <View
              style={[a.flex_col, a.flex_1, a.align_center]}
              key={`tabBar-item-${i}`}>
              <TouchableOpacity
                // key={`tabBar-item-${i}`}
                accessibilityRole="button"
                accessibilityHint=""
                accessibilityLabel={`Back`}
                testID={`tabBar-item-${i}`}
                style={[
                  styles.tabItem,
                  i === index && [
                    styles.activeTab,
                    // {borderBottomColor: t.palette.primary},
                  ],
                ]}
                onPress={() => setIndex(i)}>
                <Animated.Text style={[a.font_bold, a.pb_xs, t.atoms.text]}>
                  {route.title}
                </Animated.Text>
              </TouchableOpacity>
              {i === index && (
                <View
                  style={{
                    width: 30,
                    borderTopWidth: 3,
                    borderColor: t.palette.primary,
                  }}
                />
              )}
            </View>
          )
        })}
      </View>
    )
  }

  const onPressItem = (item: ActorDefs.ProfileViewBasic) => {
    const _index = selected.findIndex(_item => _item.did === item.did)
    if (_index === -1) {
      setSelected(selected.concat(item))
    }
  }

  const renderScene = ({route}: any) => {
    switch (route.key) {
      case 'Followers':
        return <Followers onPress={onPressItem} selecteds={selected} />
      case 'Following':
        return <Following onPress={onPressItem} selecteds={selected} />
    }
  }

  const renderItem = ({item}: {item: ActorDefs.ProfileViewBasic}) => {
    return <Actor actor={item} onPress={onPressItem} />
  }

  const onDelete = (actor: ActorDefs.ProfileViewBasic) => {
    const _selected = selected.filter(item => item.did !== actor.did)
    setSelected(_selected)
  }

  const {data: autocompleteData} = useActorAutocompleteQuery(keyword, true)

  // const showTab = keyword ? 'none' : 'flex'

  return (
    <View style={[a.flex_1, {marginHorizontal: -10, marginTop: -10}]}>
      <View style={[a.flex_row, a.justify_between]}>
        <Button
          style={[styles.left]}
          label="guarantor cancel"
          onPress={() => {
            control.close()
          }}>
          <Text style={[{fontSize: 14}, t.atoms.text]}>
            <Trans>Cancel</Trans>
          </Text>
        </Button>
        <View>
          <Text style={[t.atoms.text, {fontSize: 16, fontWeight: 'bold'}]}>
            <Trans>Guarantors</Trans>
          </Text>
        </View>
        {/* <Button
          style={[styles.right]}
          label="guarantor done"
          onPress={() => {
            onDone?.(selected)
            control.close()
          }}> */}
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            borderRadius: 25,
            backgroundColor: t.palette.primary,
            paddingHorizontal: 10,
            paddingVertical: 8,
            opacity: selected.length > 0 ? 1 : 0.5,
          }}
          onPress={() => {
            onDone?.(selected)
            control.close()
          }}>
          <Text style={[{color: t.palette.black, fontWeight: 'bold'}]}>
            <Trans>Done</Trans>
          </Text>
        </TouchableOpacity>
        {/* </Button> */}
      </View>
      <View style={[a.mt_md]}>
        <SdlInput
          value={keyword}
          setValue={setKeyword}
          style={{borderRadius: 10, height: 42}}
          placeholder="Search"
        />
        {/* <TextField.Root>
          <TextField.Icon icon={At} />
          <TextField.Input
            testID="handleInput"
            style={[{height: 44}]}
            onChangeText={setKeyword}
            label="..."
            value={keyword}
            // autoCapitalize="none"
            // autoCorrect={false}
            // autoFocus
            // autoComplete="off"
          />
        </TextField.Root> */}
      </View>
      <View style={[a.flex_row, a.flex_wrap, a.mt_md, a.gap_sm]}>
        {selected.length > 0 &&
          selected.map(item => {
            return (
              <Actor
                selected
                showClose
                size={24}
                key={item.did}
                actor={item}
                onDelete={onDelete}
              />
            )
          })}
      </View>
      {keyword && (
        <View style={[a.mt_sm, isWeb && {height: 410}]}>
          {autocompleteData?.map(item => renderItem({item}))}
        </View>
      )}
      {!keyword && (
        <TabView
          style={[
            {
              height: keyword ? 0 : 500,
              backfaceVisibility: keyword ? 'hidden' : 'visible',
              // maxHeight: 400,
            },
            a.mt_md,
          ]}
          navigationState={{index, routes}}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          onIndexChange={setIndex}
          initialLayout={{width: layout.width}}
        />
      )}
    </View>
  )
}

export const useGuarantor = () => {
  const control = Dialog.useDialogControl()
  const [actors, setActors] = React.useState<ActorDefs.ProfileViewBasic[]>([])
  const dialog = (
    <Dialog.Outer
      control={control}
      nativeOptions={{sheet: {snapPoints: ['100%']}}}>
      <Dialog.Handle />

      <Dialog.ScrollableInner
        accessibilityDescribedBy="dialog-description"
        accessibilityLabelledBy="dialog-title">
        <View style={[a.relative, a.gap_md, a.w_full, a.flex_1]}>
          <PostGuarantor control={control} actors={actors} onDone={setActors} />
        </View>
      </Dialog.ScrollableInner>
    </Dialog.Outer>
  )

  return {
    control,
    dialog,
    actors,
  }
}

const styles = StyleSheet.create({
  left: {
    // width: '12%',
  },
  headerText: {
    fontSize: 14,
    fontWeight: 800,
  },
  right: {
    // width: '10%',
  },

  container: {
    flex: 1,
  },
  tabBar: {
    flexDirection: 'row',
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    padding: 6,
    opacity: 0.5,
  },
  activeTab: {
    opacity: 1,
    fontWeight: 600,
    // borderBottomWidth: 3,
  },
})
